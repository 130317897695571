import { getParams } from '@/utils/getParams';

function getRequest(id: string) {
  const base = import.meta.env.VITE_APP_WIDGET_SERVICE_PUBLIC_URL;
  const url = `${ base }/v1/request/${ id }`;

  return fetch(url).then(res => res.json());
}

function getProductSettings(id: string, payload: Record<string, unknown>) {
  const params = {
    ...payload,
    originalUrl: document.URL,
  };

  const base = import.meta.env.VITE_APP_WIDGET_SERVICE_PUBLIC_URL;

  const filteredParamsArray = Object.entries(params).filter(a => a[1]);
  const stringParams = new URLSearchParams(filteredParamsArray);
  const url = `${ base }/v2/product/${ id }/settings?${ stringParams }`;

  return fetch(url).then(res => res.json());
}

const params = getParams();

export function loadRequest() {
  if (params.businessAccountProductUuid && !params.requestId) {
    return getProductSettings(params.businessAccountProductUuid, {
      transactionFlow: params.transactionFlow,
      partnerUserId: params.partnerUserId,
      successReturnURL: params.successReturnURL,
      failureReturnURL: params.failureReturnURL,
      signature: params.signature,
    });
  }

  return getRequest(params.requestId);
}
