const WIDGET_EVENT_NAMESPACE = 'widget';

const STATE_CLOSED = 'closed';
const STATE_CANCELLED = 'cancelled';
const STATE_REJECTED = 'rejected';
const STATE_COMPLETED = 'completed';
const STATE_NEW_TRANSACTION = 'start-new-transaction';

export const emitToParent = (eventName, payload = {}) => {
  window.parent.postMessage(JSON.stringify({
    namespace: WIDGET_EVENT_NAMESPACE,
    name: eventName,
    payload,
  }), '*');
};

export const STATES = {
  STATE_CLOSED,
  STATE_REJECTED,
  STATE_CANCELLED,
  STATE_COMPLETED,
  STATE_NEW_TRANSACTION,
};
