import('/src/assets/style/loader.css');

const overlay = document.getElementById('overlay');

const removeOverlay = () => {
  overlay.remove();
  document.body.style.overflow = '';
};

function showOverlay(el) {
  const prevChild = overlay.firstElementChild;

  const child = document.createElement('div');
  child.classList.add('overlay-child');
  child.appendChild(el);

  if (prevChild) {
    child.addEventListener('animationend', () => prevChild.remove(), { once: true });
  }

  overlay.appendChild(child);
}

const showHealthPopup = async () => {
  const { renderHealthOverlay } = await import('@/overlay.js');
  showOverlay(renderHealthOverlay());
};

const showFailPopup = async () => {
  const { renderFailOverlay } = await import('@/overlay.js');
  showOverlay(renderFailOverlay());
};

const showErrorPopup = async () => {
  const { renderErrorOverlay } = await import('@/overlay.js');
  showOverlay(renderErrorOverlay());
};

export class HealthCheckError extends Error {
  constructor(message) {
    super();
    this.message = message;
    this.name = 'HealthCheckError';
  }
}

const ready = fn => {
  if (document.readyState !== 'loading') {
    fn();
  } else {
    document.addEventListener('DOMContentLoaded', fn);
  }
};

let timers = [];

ready(() => {
  timers.push(setTimeout(showHealthPopup, 15_000));
  timers.push(setTimeout(() => {
    showFailPopup();
    throw new HealthCheckError('Health check failed');
  }, 60_000));
});

window.addEventListener('loading-status-error', () => {
  timers.forEach(clearTimeout);
  showErrorPopup();
});
window.addEventListener('pb-widget-loaded', () => {
  timers.forEach(clearTimeout);
  setTimeout(removeOverlay, 100);
});
