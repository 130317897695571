import { isInsideIframe } from '@/utils/isInsideIframe';
import { Layouts } from '@/constants/layouts';

import { has as hasPaybisParams } from '@/standalone/paybis/params';
import { has as hasLedgerParams } from '@/standalone/ledger/params';

const checkForValue = value => value !== null && value !== '' && value !== undefined;

const settings = {
  canClose: isInsideIframe(), // default setting
  layout: Layouts.DEFAULT,
  standalone: {},
};

export const setCanClose = canClose => settings.canClose = canClose;

export const canClose = () => settings.canClose;
export const setStandaloneParams = params => {
  settings.standalone = {
    ...params,
  };
};

export const hasStandaloneParams = () => {
  return hasLedgerParams(settings.standalone) || hasPaybisParams(settings.standalone);
};

export const hasWalletAddress = () => checkForValue(settings.standalone.cryptoAddress);

export const getWalletAddress = () => {
  if (!hasWalletAddress()) {
    throw new Error('Could not find valid wallet address');
  }
  const { cryptoAddress } = settings.standalone;

  return cryptoAddress;
};

export const setLayout = layout => { settings.layout = layout; };

export const hasLayout = () => checkForValue(settings.layout);

export const getLayout = () => {
  if (!hasLayout()) {
    throw new Error('Could not find layout');
  }
  return settings.layout;
};

export const getStandaloneParams = () => {
  const {
    cryptoAddress,
    currencyCodeTo,
    currencyCodeFrom,
    amountFrom,
    amountTo,
    transactionFlow,
    paymentMethod,
  } = settings.standalone;

  return {
    cryptoAddress,
    currencyCodeTo,
    currencyCodeFrom,
    amountFrom: Number(amountFrom) > 0 ? amountFrom : null,
    amountTo: Number(amountTo) > 0 ? amountTo : null,
    transactionFlow,
    paymentMethod,
  };
};
