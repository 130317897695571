import storage from '@/services/appPersistStorage';
import { setCanClose, setLayout, setStandaloneParams } from '@/services/widgetSettings';
import { handleUrlParams } from '@/standalone/handleParams';
import { layoutValidator, localeValidator } from '@/validators';
import camelCase from 'lodash/camelCase';

export const REQUEST_ID = 'requestId';
export const LOCALE = 'locale';
export const LANG = 'lang';
export const REFRESH_TOKEN = 'refreshToken';
export const ONE_TIME_TOKEN = 'oneTimeToken';
export const CAN_CLOSE = 'canClose';
export const BUSINESS_ACCOUNT_PRODUCT_UUID = 'partnerId';
export const TRANSACTION_FLOW = 'transactionFlow';
export const PARTNER_USER_ID = 'partnerUserId';
export const SUCCESS_RETURN_URL = 'successReturnUrl';
export const FAILURE_RETURN_URL = 'failureReturnUrl';
export const SWITCH_EXCHANGE_FORM_FLOW = 'switchExchangeFormFlow';
export const LAYOUT = 'layout';
export const INDEX_PAGE = 'indexPage';

const url = new URL(window.location);

const removeParamsFromUrl = params => {
  params.forEach(param => {
    url.searchParams.delete(param);
  });

  window.history.replaceState(null, null, url);
};

const getRequestParamsByUrlParams = () => {
  const urlParams = Array.from(new URLSearchParams(window.location.search).entries()).reduce((result, parameter) => {
    result.set(camelCase(parameter[0]), parameter[1]);

    return result;
  }, new Map());

  const requestId = urlParams.get(REQUEST_ID) ?? null;
  const refreshToken = urlParams.get(REFRESH_TOKEN);
  const oneTimeToken = urlParams.get(ONE_TIME_TOKEN);
  const canClose = urlParams.get(CAN_CLOSE);
  const businessAccountProductUuid = urlParams.get(BUSINESS_ACCOUNT_PRODUCT_UUID);
  const partnerUserId = urlParams.get(PARTNER_USER_ID);
  const successReturnURL = urlParams.get(SUCCESS_RETURN_URL);
  const failureReturnURL = urlParams.get(FAILURE_RETURN_URL);
  const switchExchangeFormFlow = urlParams.get(SWITCH_EXCHANGE_FORM_FLOW);
  const layout = layoutValidator(urlParams.get(LAYOUT));
  const indexPage = getIndexPage(urlParams);
  let locale = urlParams.get(LOCALE) || urlParams.get(LANG);
  try {
    localeValidator(locale);
  } catch {
    locale = null;
  }

  return {
    requestId,
    locale,
    refreshToken,
    oneTimeToken,
    canClose,
    businessAccountProductUuid,

    ...handleUrlParams(urlParams),
    switchExchangeFormFlow,
    indexPage,
    partnerUserId,
    successReturnURL,
    failureReturnURL,
    layout,
  };
};

const getIndexPage = urlParams => {
  const value = urlParams.get(INDEX_PAGE);
  if (['paymentMethodList', 'exchangeForm', 'applePay'].includes(value)) {
    return value;
  }
  return null;
};

export const removeSensitiveDataFromUrl = () => removeParamsFromUrl([
  REFRESH_TOKEN,
  ONE_TIME_TOKEN,
]);

const requestParams = getRequestParamsByUrlParams();
const {
  cryptoAddress,
  currencyCodeTo,
  currencyCodeFrom,
  amountFrom,
  amountTo,
  transactionFlow,
  paymentMethod,
  bankResidency,
  layout,

  canClose,
} = requestParams;

if (canClose === 'true' || canClose === 'false') {
  setCanClose(canClose === 'true');
}

setLayout(layout);

setStandaloneParams({
  cryptoAddress,
  currencyCodeTo,
  currencyCodeFrom,
  amountFrom,
  amountTo,
  transactionFlow,
  paymentMethod,
  bankResidency,
});

export function getParams() {
  return requestParams;
}
