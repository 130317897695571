export const loadPartnerConfig = themeName => {
  const configList = import.meta.glob('/public/partners/*/config.json');

  const partnerJson = configList[`/public/partners/${themeName}/config.json`];
  if (partnerJson) {
    return partnerJson();
  }

  throw new Error('Failed to load theme config');
};
